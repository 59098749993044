import React from 'react';

function Footer() {

  return (
    <div className="main-footer">
      <div className='main-footer-content'>
        <ul className="main-footer-links">
          <li><a href='mailto:contact@dateflix.app' rel="noreferrer" target='_blank'>Help</a></li>
        </ul>
        <p className="footer-text">Copyright © Dateflix™ { new Date().getFullYear() }</p>
      </div>
    </div>
  )
}

export default Footer