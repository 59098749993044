import React, { useEffect } from 'react';

function Home() {

  useEffect(() => {
    window.location.replace('https://dateflix.app');
  }, []);
  
    return (
      <>
      </>
    );
}

export default Home;