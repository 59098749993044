import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/shared/Loader';
import api from '../auth/api';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { HttpStatusCode } from 'axios';

function PasswordReset() {

    const navigate = useNavigate();

    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [resetSuccess, setResetSuccess] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordWarning, setPasswordWarning] = useState(false);
    const [valid, setValid] = useState(false);

    const redirect = (time = 3000) => {
      setTimeout(() => {
        window.location.replace('https://dateflix.app');
      }, time);
    };

    const isPasswordValid = (password) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
        return passwordRegex.test(password);
    };

    const handleChange = (event) => {
        setPassword(event.target.value);
        setPasswordWarning(!isPasswordValid(event.target.value));
    };

    const handleChangeConfirm = (event) => {
        const confirmedPassword = event.target.value;
        setConfirmPassword(confirmedPassword);
        setValid(password === confirmedPassword && password !== '');
    };

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    const verifyToken = async () => {

        setLoading(true);

        try {
            const searchParams = new URLSearchParams(window.location.search);
            const token = searchParams.get('token');

            if (token) {
                const result = await api.get(`/auth/token/${token}`);
                setResponse(result);
            } else {
              setError({ status: 400 })
            }
        } catch (err) {
            setError(err.response);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        verifyToken();
    }, [navigate]);

    const handleResetPasswordRequest = async () => {
        setLoading(true);

        if (valid && isPasswordValid(password)) {
            const searchParams = new URLSearchParams(window.location.search);
            const token = searchParams.get('token');

            try {
                if (token) {
                    const result = await api.post('/auth/reset', { token, password });
                    setResetSuccess(result.status === HttpStatusCode.Ok);
                }
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
                redirect();
            }
        }
    };

    return (
        <div className='reset-page-container'>
          <img src="./images/app-logo-white.png" 
          className="img-responsive" height={100} width={100} 
          alt="App Logo" onClick={() => window.location.href = 'https://dateflix.app'}/>
            { loading && <Loader />}

            { resetSuccess ? (
                <div className='reset-page-content'>
                    <h3 className='reset-title'>Success!</h3>
                    <p className='reset-text'>Your new password has been set for your account. You may be asked to sign back in again with your new credentials</p>
                </div>
            ) : response && response.status === 200 && response.data.result === 'RTV' ? (
                <div className='reset-page-content'>
                    <h3 className='reset-title'>Reset account password</h3>
                    <p className='reset-text'>Enter a new password for your account</p>
                    {passwordWarning && (
                        <p className='password-warning-text'>
                            Ensure your password is at least 8 characters with 1 lowercase, 1 uppercase & 1 symbol.
                        </p>
                    )}
                    <div className='password-form-container'>
                        <div style={{ position: 'relative' }}>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                id='password'
                                name='password'
                                className='password-input'
                                value={password}
                                onChange={handleChange}
                                placeholder='Enter new password'
                            />
                            <span
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    right: '5px',
                                    transform: 'translateY(-50%)',
                                    cursor: 'pointer',
                                }}
                                onClick={handleTogglePassword}
                            >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </span>
                        </div>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            id='confirmpassword'
                            name='confirmpassword'
                            className='password-input'
                            value={confirmPassword}
                            onChange={handleChangeConfirm}
                            placeholder='Confirm new password'
                        />
                        {valid && isPasswordValid(password) && (
                            <button type='button' onClick={handleResetPasswordRequest} className='button'>
                                Reset Password
                            </button>
                        )}
                    </div>
                </div>
            ) : error && error.status === 400 ? (
                <div className='reset-page-content'>
                    <h3 className='reset-title'>Oh no!</h3>
                    <p className='reset-text'>
                        Something's not quite right. Please check the link you received or request a new reset link from the app.
                    </p>
                </div>
            ) : error && error.status === 500 ? (
                <div className='error'>
                    <h3 className='verification-title'>Oops</h3>
                    <p className='verification-text'>Something has gone wrong on our end. Give us a moment to sort it out!</p>
                </div>
            ) : response && response.status === 200 && response.data.result === 'RTE' ? (
                <div className='reset-page-content'>
                    <h3 className='reset-title'>Expired link</h3>
                    <p className='reset-text'>
                        It appears the reset password link has expired. Please request a new reset link from the app.
                    </p>
                </div>
            ) : response && response.status === 200 && response.data.result === 'RTI' ? (
                <div className='reset-page-content'>
                    <h3 className='reset-title'>Invalid link</h3>
                    <p className='reset-text'>
                        It appears the reset link is no longer valid. Please request a new reset link from the app.
                    </p>
                </div>
            ) : null}
        </div>
    );
}

export default PasswordReset;
