import React from 'react';

function Loader() {

    return (
    <div className="loader-container">
        <div className="loader"></div>
    </div>
    );
}

export default Loader