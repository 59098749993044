import React, { useEffect, useState } from 'react';
import Loader from '../components/shared/Loader';
import api from '../auth/api';
import { HttpStatusCode } from 'axios';

function Verification() {

    const [verificationStatus, setVerificationStatus] = useState(false);
    const [responseMessage, setResponseMessage] = useState({ title: '', message: ''});
    const [loading, setLoading] = useState(true);

    const redirect = (time) => {
      setTimeout(() => {
        window.location.replace('https://dateflix.app');
      }, time);
    };

    useEffect(() => {

      const processRequest = async () => {

        setLoading(true);
  
        try {
          const searchParams = new URLSearchParams(window.location.search);
          const token = searchParams.get('token');
  
          if (token) {
  
            const response = await api.post('auth/verify', { token } );
  
            if (response.status === HttpStatusCode.Ok) {
              setVerificationStatus(true);
              setResponseMessage({
                title: 'Verification successful!',
                message: 'You can now close this page and return to the app.'
              });
            }
  
          } else {
            redirect(1000);
          }
        } catch (err) {
  
          setVerificationStatus(false);
  
          const responseStatus = err.response?.status;
          const responseData = err.response?.data;
  
          switch(responseStatus) {
            case HttpStatusCode.BadRequest:
              setResponseMessage({
                title: 'Oh no',
                message: 'Something is not quite right. Please check the link you received or request a new verification link from the app.'
              });
              break;
  
            case HttpStatusCode.Unauthorized:
              setResponseMessage({
                title: responseData.error,
                message: responseData.message
              });
              break;
  
            case HttpStatusCode.Conflict:
              setResponseMessage({
                title: responseData.error,
                message: responseData.message
              });
              break;
  
            case HttpStatusCode.InternalServerError:
              setResponseMessage({
                title: 'Oops',
                message: 'Something went haywire on our end. Give us a moment to sort it out!'
              });
              break;
  
            case HttpStatusCode.TooManyRequests:
              setResponseMessage({
                title: 'Hmm',
                message: 'You’ve made too many requests. Please wait and try again later.'
              });          
              break;
  
            default:
              break;
          }
        } finally {
          setLoading(false);
          redirect(3500);
        }
      }
      
      processRequest();
    }, [])
  
    return (
      <div className='verification-page-container'>
        { loading && (
          <Loader />
        )}
        <div className='verification-content'>
          <img src="./images/app-logo-white.png" className="img-responsive" height={100} width={100} alt="App Logo"></img>
          { verificationStatus === true ? (
          <div className='success'>
            <h3 className='verification-title'>{responseMessage.title}</h3>
            <p className='verification-text'>{responseMessage.message}</p>
          </div>
          ) : (
          <div className='error'>
            <h3 className='verification-title'>{responseMessage.title}</h3>
            <p className='verification-text'>{responseMessage.message}</p>
          </div>
          )}
        </div>
      </div>
    );
}

export default Verification;