import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Footer from './components/shared/Footer';
import Verification from './pages/Verification';
import './App.css';
import PasswordReset from './pages/PasswordReset';

function App() {
  return (
    <div className="main">
       <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/verify' element={<Verification />} />
          <Route path='/reset' element={<PasswordReset />} />
        </Routes>
      <Footer />
    </div>
  );
}

export default App;
